import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {
    Accordion,
    IAccordionClassNames,
    List,
    PageContainer,
    PageHeader,
    PageRow,
    SelectListGroup,
    SelectListItem
} from "@reapptor-apps/reapptor-react-components";
import {DynamicPageData, DynamicPageResponse, DynamicPageResponseSingleType} from "@/models/cms/DynamicPageResponse";
import ReactMarkdown from "react-markdown";
import styles from "./Services.module.scss";
import {BasePageParameters, ch, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import FenixAppController from "@/pages/FenixAppController";
import Localizer from "@/localization/Localizer";
import {FavoriteServiceList} from "@/models/cms/FavoriteServiceList";
import PageDefinitions from "@/providers/PageDefinitions";
import {LoadingSpinner} from "@/components/LoadingSpinner/LoadingSpinner";
import {GetServicesResponse} from "@/models/cms/GetServicesResponse";

export interface IServicesParams extends BasePageParameters {}

interface IServicesProps {}

interface IServicesState {
    services: DynamicPageData[];

    medicalFields: DynamicPageData[];
    clinics: DynamicPageData[];
    instructionServices: DynamicPageData[];
    favorites: DynamicPageData[] | null;
    allServices: DynamicPageData[];

    selectedClinic: DynamicPageData | null;
    loading: boolean | null;
}

export default class Services extends AnonymousPage<IServicesProps, IServicesState> {
    state: IServicesState = {
        services: [],
        medicalFields: [],
        instructionServices: [],
        clinics: [],
        favorites: [],
        allServices: [],
        selectedClinic: null,
        loading: null
    };

    static accordionClassNames: IAccordionClassNames = {
        headerContainer: styles.accordionHeaderContainer
    };

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        this.setState({loading: true})
        const servicesData: GetServicesResponse = await this.getAsync("/api/Application/GetServices");
        if (servicesData.favorites && servicesData.favorites.data.attributes) {
            let favoriteList: DynamicPageData[] = []
            if(servicesData.favorites.data.attributes.clinics){
                favoriteList = favoriteList.concat(servicesData.favorites.data.attributes.clinics.data)
            }
            if(servicesData.favorites.data.attributes.medical_fields){
                favoriteList = favoriteList.concat(servicesData.favorites.data.attributes.medical_fields.data)
            }
            if(servicesData.favorites.data.attributes.services){
                favoriteList = favoriteList.concat(servicesData.favorites.data.attributes.services.data)
            }
            if(favoriteList.length > 0){
                favoriteList.sortBy(item => item.attributes.name)
            }
            
            await this.setState({favorites: favoriteList})
        }
        
        if(servicesData.services && servicesData.services.data){
            var serviceList: any[] = [];
            if(servicesData.services.data.attributes.medical_fields?.data){
                serviceList = [...serviceList, ...servicesData.services.data.attributes.medical_fields?.data];
            }
            if(servicesData.services.data.attributes.services?.data){
                serviceList = [...serviceList, ...servicesData.services.data.attributes.services?.data];
            }
            if(servicesData.services.data.attributes.clinics?.data){
                serviceList = [...serviceList, ...servicesData.services.data.attributes.clinics?.data];
            }
            serviceList.sortBy(item => item.attributes.name)
            
            await this.setState({allServices: serviceList})
            
        }
        if(servicesData.instructionServices && servicesData.instructionServices.data){
            if(servicesData.instructionServices.data.attributes.services){
                const instructionServiceList = servicesData.instructionServices.data.attributes.services.data;
                instructionServiceList.sortBy(item => item.attributes.name)
                
                await this.setState({instructionServices: instructionServiceList})
            }
            
        }
        this.setState({loading: false})
        window.scrollTo(0, 0);
    }

    public routeToServicePage = async (item: DynamicPageData) => {
        if('clinic_sub_items' in item.attributes){
            await PageRouteProvider.redirectAsync(PageDefinitions.servicePageRoute("clinic", item.id))
        }
        else if('hideFromDoctorsListing' in item.attributes){
            await PageRouteProvider.redirectAsync(PageDefinitions.servicePageRoute("medical-field", item.id))
        }
        else{
            await PageRouteProvider.redirectAsync(PageDefinitions.servicePageRoute("service", item.id))
        }
    }
    
    static serviceItem = (props: {item: DynamicPageData; onClick: () => void; imgSrc: string}) => {
        return (
          <button
            className={styles.serviceItem}
            onClick={async () => {
                props.onClick();
            }}
          >
              <img src={props.imgSrc} height={40} width={40} />
              <div>{props.item.attributes.name}</div>
          </button>
        );
    }

    public render(): React.ReactNode {
        return (
          <PageContainer fullWidth className={styles.pageContainer}>
              <PageHeader title={Localizer.servicesPageTitle} className={styles.pageHeader} />
              {this.state.loading ?
                <LoadingSpinner />
              :
              <>

                  <div className={styles.pageContent}>
                      <div className={styles.fluidContent}>
                          <div className={styles.favoritesContainerDiv}>
                              <h3>{Localizer.servicesFavoriteServices}</h3>
                              <div className={styles.servicesGrid}>
                                  {(this.state.favorites ?? []).map(item => {
                                      return(
                                        <Services.serviceItem
                                          imgSrc={FenixAppController.getIcon(item)}
                                          item={item}
                                          onClick={() => this.routeToServicePage(item)}
                                          key={item.attributes.name}
                                        />
                                      )
                                  })}
                              </div>
                          </div>
                          <div className={styles.servicesContainerDiv}>
                              <h3>{Localizer.servicesAllServicesAlphabetical}</h3>
                              <div className={styles.servicesGrid}>
                                  {(this.state.allServices ?? []).map(item => {
                                      return(
                                        <Services.serviceItem
                                          imgSrc={FenixAppController.getIcon(item)}
                                          item={item}
                                          onClick={() => this.routeToServicePage(item)}
                                          key={item.attributes.name+item.id}
                                        />
                                      )
                                  })}
                              </div>
                          </div>
                          {(this.state.instructionServices && this.state.instructionServices.length > 0) &&
                            <div className={styles.instructionServicesContainerDiv}>
                                <h3>{Localizer.servicesInstructionServices}</h3>
                                <div className={styles.servicesGrid}>
                                    {(this.state.instructionServices ?? []).map(item => {
                                        return(
                                          <Services.serviceItem
                                            imgSrc={FenixAppController.getIcon(item)}
                                            item={item}
                                            onClick={() => this.routeToServicePage(item)}
                                            key={item.attributes.name+item.id}
                                          />
                                        )
                                    })}
                                </div>
                            </div>
                          }
                      </div>
                  </div>
              </>}
          </PageContainer>
        );
    }
}